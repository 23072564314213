@mixin text-resetter {
  margin: 0;
  padding: 0;
}

.Button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  box-sizing: border-box;
  padding-inline: 22px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%);

  & :global .MuiCircularProgress-root {
    color: var(--white);
    margin-left: 10px;
  }

  &__Text {
    @include text-resetter;
    
    color: var(--white);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.46;
    font-size: 15px;

    &--Black {
      color: var(--Black);
    }
  }

  &--Transparent {
    background-color: var(--white);
    box-shadow: none;
  }

  &--Disabled {
    cursor: not-allowed;
    background-color: gray;
  }
}

.Drawer {
  width: 450px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex: 1 0;
  flex-direction: column;

  &__Header {
    margin-bottom: 32px;
  }

  &__HeaderText {
    @include text-resetter;

    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.25px;
    margin-bottom: 6px;
  }

  &__HeaderDescription {
    @include text-resetter;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #424242
  }

  &__AdvertiserText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__ProjectNameText {
    @include text-resetter;
    
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
  }

  &__SpecificationRow {
    display: flex;

    :first-child {
      margin-right: 14px;
    }
  }

  &__SpecificationRowText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &__ProjectSpecifications {
    height: 100%;

    & > * + * {
      margin-top: 16px;
    }
  }
}

.Footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 40px;
  gap: 10px;
}
