@mixin text-resetter {
  margin: 0;
  padding: 0;
}

.Header {
  min-height: 232px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 0;
  box-sizing: border-box;

  &__LogoWrapper {
    height: 64px;
    width: 64px;
    flex-shrink: 0;
    background-color: var(--white);
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    justify-content: center;

    &--Large {
      height: 64px;
      width: 64px;
    }

    &--Medium {
      height: 44px;
      width: 42px;
    }
  }

  &__Logo {
    max-height: 100%;
    max-width: 100%;
  }

  &__LogoSkeleton {
    & :global(.MuiSkeleton-root) {
      border-radius: 8px;
      height: 64px;
      width: 64px
    }
  }

  &__LogoInitial {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(240 240 240 / 100%);

    &--Large {
      height: 64px;
      width: 64px;
    }

    &--Medium {
      height: 44px;
      width: 42px;
    }
  }

  &__LogoInitialText {
    @include text-resetter;

    font-weight: bold;

    &--Large {
      font-size: 32px;
    }

    &--Medium {
      font-size: 20px;
    }
  }

  &__AdvertiserTextSkeleton {
    & :global(.MuiSkeleton-root) {
      height: 20px;
      width: 150px
    }

    &--MT10 {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }


  &__BackContainer {
    all: unset;
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    cursor: pointer;

    &> :first-child {
      margin-right: 13px;
    }
  }

  &__BackText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &__DetailContainer {
    display: flex;
    justify-content: space-between;
  }

  &__AdvertiserDetails {
    display: flex;

    &> :first-child {
      margin-right: 20px
    }

    & :global(.MuiSkeleton-root) {
      border-radius: 5px
    }
  }

  &__DetailStatusText {
    @include text-resetter;

    border: 1px solid #000;
    height: fit-content;
    padding: 4px 6px;
  }

  &__DetailAdvertiserText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  &__DetailProjectTextWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }

  &__SelectText {
    @include text-resetter;

    color: var(--black);
    text-decoration: none;
    display: flex;
    cursor: pointer;
    align-items: center;

    &> :first-child {
      margin-right: 10px;
    }

    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-left: 5px;
    letter-spacing: 0.15px;
    text-align: left;

  }

  &__DetailProjectText {
    @include text-resetter;

    font-size: 32px;
    margin-right: 20px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
  }

  &__Tab {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  &__TabItem {
    margin-right: 20px;
    padding-bottom: 16px;
    height: fit-content;
    cursor: pointer;
    text-decoration: none;
    color: inherit;


    &--Active {
      border-bottom: 2px solid var(--black);
    }
  }


  &__TabItemText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.4;

    &--Active {
      opacity: 1;
    }
  }


  &__ShareButton {
    all: unset;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4px;
    border: 1px solid var(--black);
    text-transform: uppercase;
    padding: 5px  10px;
    margin-left: auto;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &>img {
      margin-right: 10px;
    }

    &--Hidden {
      display: none;
    }
  }
}
