.VoteList {
  position: absolute;
  left: 22px;
  top: 10px;
  z-index: 9;

  &__Wrapper {
    display: flex;
  }

  &__Text {
    font-size: 13px;
    text-transform: capitalize;
  }

  &__UserIcon {
    display: flex;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    align-items: center;
    border-radius: 200px;
    justify-content: center;
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 50%));
    box-shadow: 0px -25px 20px -20px rgba(255 255 255 / 45%),
      25px 0px 20px -20px rgba(255 255 255 / 45%),
      0px 25px 20px -20px rgba(255 255 255 / 45%),
      0px 0px 0px 2px rgba(255 255 255 / 45%);
    width: 32px;
    height: 32px;
    margin-left: -10px;
    background: #F9F5FF;

    &--InList {
      width: 24px;
      height: 24px;
      margin-left: 0;
    }

    &--IsCount {
      background: #F0F0F0;
    }
  }

  &__UserPlaceholder {
    height: 75%;
    width: 75%;
  }

  &__UserIconImg {
    border-radius: 20px;
    height: 30px;

    &--Hide {
      display: none;
    }
  }

  &__MainUserHeader {
    padding: 0.5em;
    max-height: 220px;
    min-width: 120px;
    max-width: 180px;
    overflow-y: auto;
  }

  &__HeaderText {
    font-size: 14px;
    font-weight: 600;

    &--Grey {
      color: #9E9E9E;
    }
  }

  &__OtherUserWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
  }

  &__IconText {
    font-size: 12px;
    font-weight: 600;
    color: #7F56D9;

    &--Large {
      font-size: 14px;
    }
  }

  &__Username {
    font-size: 12px;
    font-weight: 600;
    margin-left: 0.5em;
  }

  &__OtherUsername {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    width: 150px;

    &--Large {
      font-size: 14px;
    }
  }
}
