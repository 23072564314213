.VideoContainer {
  left: 0;
  right: 0;
  bottom:0;
  z-index:100;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgb(0 0 0 / 80%);

  &__Header {
    display: flex;
    height: 100px;
    padding: 0 53px;
    align-items: center;
    justify-content: space-between;
    background: rgb(60 60 60 / 95%);

    &__Back {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__Text {
        margin-left: 10px;
        color: var(--white);
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  &__Main {
    flex: 1;
    display: flex;
    justify-content: space-between;

    &__SidebarToggler {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &__Logo {
        width: 54px;
        height: 54px;
        display: flex;
        cursor: pointer;
        background:  var(--black);
        align-items: center;
        justify-content: center;
      }

      &--Left {
        justify-content: flex-start;
      }
    }

    &__Video {
      height: 100%;
      width: 100%;
    }
  }
}

.AdminActions {
  &__Button {
    cursor: pointer;
    background: none;
    padding: 10px 80px;
    border: 1px solid var(--white);
    color: var(--white);
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__Delete {
    margin-left: 10px;
    
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.Drawer {
  height: 100%;
  width: 380px;
  display: flex;
  flex-direction: column;

  &__Header {
    flex: 1;
    padding: 40px;

    &__Text {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 34px;
      font-weight: bold;
    }
  }

  &__Footer {
    height: 100px;
    display: flex;
    align-items: center;
    padding-inline: 40px;
    justify-content: flex-end;

    &__Button {
      border: none;
      background: none;
      padding-inline: 20px;
      cursor: pointer;

      &__Text {
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;

        &--White {
          color: var(--white);
        }
      }
    }
  }
}
