.Preview {
  height: 250px;
  width: 250px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
