.Dialog {
  &__Wrapper {
    padding: 40px;
    position: relative;
  }

  &__CloseIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--antiFlashWhite);
    }
  }

  &__Title {
    font-size: 34px;
    font-weight: bold;
    margin: 0;
  }
  
  &__Description {
    margin: 0;
    font-size: 16px;
    margin-top: 10px;
    padding-right: 15px;
  }

  &__Footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;
    gap: 5px;
  }

  &__BtnTxt {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;

    &--White {
      color: var(--white);
    }
  }
}
