.Brands {
  padding: 8px 64px;
  list-style: none;

  &__Item {
    margin-bottom: 8px;
  }
}

.Brand {
  display: flex;
  flex-flow: row nowrap;

  &__Logo {
    flex: 0 0 auto;
    margin: 0;

    background-color: #DEDEDE;

    width: 100px;
    height: 100px;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__Info {
    flex: 1 1 auto;

    padding: 8px 16px;
    font-size: 16px;
  }
}
