.Sidebar {
  &__ParentLink {
    color: var(--black-inactive);
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 0 32px;
    align-items: center;
    transition: all 0.5s ease;

    &--active {
      color: var(--black);
    }
  }

  &__Link {
    align-items: center;
    color: var(--black-inactive);
    display: flex;
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    height: 48px;
    line-height: 24px;
    margin-bottom: 4px;
    text-decoration: none;
    transition: all .3s ease;

    &:hover,
    &--active {
      color: var(--black);
    }
  }

  &__MenuList {
    list-style: none;
    margin: 0;
    padding: 0 32px;

    &Item {
      margin-left: 24px;

      .Sidebar__Link {
        border-radius: 6px;
        padding: 0 16px;
      }

      :global .active {
        background-color: var(--platinum);
        color: var(--black);
      }
    }
  }

  &__LinkIcon {
    flex: 0 0 auto;
    margin-right: 16px;

    &--collapsed {
      margin: auto;
    }
  }

  &__MenuArrow {
    margin-left: auto;
    flex: 0 0 auto;
    transition: all .3s ease;

    &--open {
      transition: all .3s ease;
      transform: rotate(90deg);
    }
  }

  &__Footer {
    width: calc(100% - 52px);
    margin-inline: 16px;
    padding-inline: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    flex: 1;
    border-top: 1px solid rgba(0 0 0 / 20%);
    background: rgba(240 240 240 / 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 32px;
    padding-top: 24px;
  }

  &__FooterAvatarImg {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 16px;

    &--Initials {
      background-color: #909090;
      display: grid;
      place-items: center;
      color: var(--white);
    }
  }

  &__FooterUsernameText {
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__FooterLogout {
    height: 32px;
    display: grid;
    place-items: center;
    flex: 1;

    &--Open {
      flex: 0;
    }
  }

  &__FooterLogoutImg {
    cursor: pointer;
  }

  &__TogglerButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 10;
    height: 40px;
    width: 40px;
    left: 100%;
    top: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    background: #FAFAFA;
    box-shadow: 0px 5px 9px rgba(0 0 0 / 6%);
    border-radius: 60px;
    
    img {
      height: 20px;
      width: 20px;
    }
  }

  &__TogglerImage {
    transition: all 0.5s ease-in;

    &--Open {
      transform: scaleX(-1);
    }
  }
}
