@mixin text-resetter {
  margin: 0;
  padding: 0;
}

@mixin description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin heading {
  font-size: 20px;
  line-height: 32px;
}

.Header {  
  &__Description {
    @include text-resetter;
    @include description;
  
    margin-bottom: 12px;
    max-width: 720px;
  }
  
  &__HeaderContainer {
    display: flex;
    align-items: center;
  
    & > :first-child {
      margin-right: 8px;
    }
  }
    
  &__HeaderContainerText {
    @include text-resetter;
    @include heading;
  
    display: flex;
    align-items: center;

    &--small {
      font-size: 16px;
    }
  }
}
