.VideoPreview {
  position: relative;
  height: fit-content;

  &__Overlay {
    position: absolute;
    background-color: var(--white);
    height: 100%;
    width: 100%;
    bottom: 5px;
    z-index: 2;
    opacity: 0.55;
  }

  &__BackgroundVideoPlaceholder {
    display: flex;
    width: auto;
    height: 224px;
    align-items: center;
    justify-content: center;
    background-color: var(--antiFlashWhite) ;
    
    &__Img {
      height: 45px;
    }
  }

  &__Progress {
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 20px;
  }
}

.FooterContainer {
  display: flex;
  justify-content: space-between;
  bottom:10px;
  position: absolute;
  z-index: 2;
  width: 100%;

  &__Error {
    display: flex;
    align-items: center;
    margin-left: 10px;

    :first-child {
      margin-right: 9px;
    }
  }

  &__Remove {
    display: flex;
    margin-right: 10px;
  }

  &__RemoveButton {
    border: none;
    cursor: pointer;
    background: none;
  }

  &__RemoveButtonText {
    font-size: 12px;
    text-decoration: underline;
    color: var(--greenBlue);
  }

  &__ErrorImg {
    height: 12px;
  }

  &__ErrorText {
    font-size: 12px;
    color: var(--errorRed);
  }
}
