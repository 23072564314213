:root {
  --errorRed: #F44336;
  --greenBlue: rgb(40 110 180 / 100%);
  --white: white;
  --antiFlashWhite: #f0f0f0;
  --bright-gray: #eee;
  --gray : #808080;
  --gray12: #BDBDBD;
  --cultured: #f5f5f5;
  --winterWizard: #A0E6FF;
  --green: #3CAF6E;
  --green-light: #BEFF91;
  --blue: #286EB4;
  --blue-light: #A0E6FF;
  --orange: #FF6430;
  --orange-light: #FFB482;
  --gold: #FAAA00;
  --gold-light: #FFD973;
  --pink: #FA6EAA;
  --pink-light: #FFC8FF;
  --anti-flash-white: #f0f0f0;
  --platinum: #E7E7E7;
  --black: rgb(0 0 0);
  --black-inactive: rgba(0 0 0 / 50%);
  --font: 'degular', sans-serif;
  --chinese-white: #E0E0E0;
  --apple: #4CAF50;
  --cerise: #E1306C;
  --oruby: #9D234B;
  --coralred: #F44336;
  --metallicred: #AB2F25;
  --japaneselaurel: #357B38;
  --darkSilver: #4D4D4D;
  --transparent: transparent;
  --brightGray: #EBEBEB;
  --granite-gray: #616161;
  --lotion: #FAFAFA;
  --sonic-silver: #757575;
}

@mixin filled-button-template($bgColor, $hoverBgColor, $textColor) {
  all: unset;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgba(0 0 0 / 14%), 0px 3px 1px -2px rgba(0 0 0 / 20%);
  padding: 8px 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: $bgColor;
  color: $textColor;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $hoverBgColor;
  }

  &--Disabled {
    cursor: not-allowed;
    opacity: 0.12;
    background-color: var(--black);

    &:hover {
      background-color: var(--black);
    }
  }
}

@mixin outline-button-template($borderColor, $hoverBgColor, $textColor) {
  all: unset;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid $borderColor;
  color: $textColor;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $hoverBgColor;
  }

  &--Disabled {
    cursor: not-allowed;
    opacity: 0.12;

    &:hover {
      background-color: var(--black);
    }
  }
}

@mixin text-button-template($bgColor, $hoverBgColor, $textColor) {
  all: unset;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: $bgColor;
  color: $textColor;

  &:hover {
    background-color: $hoverBgColor;
  }

}


body {
  margin: 0;
  font-family: Degular, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 768px;
  overflow-x: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: Degular;
  src: local('Degular'), url('./assets/fonts/Degular-Regular.woff2') format('woff2'), url("./assets/fonts/Degular-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Degular;
  src: local('DegularSemibold'), url('./assets/fonts/Degular-Semibold.woff2') format('woff2'), url('./assets/fonts/Degular-Semibold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: Degular;
  src: local('DegularBold'), url('./assets/fonts/Degular-Bold.woff2') format('woff2'), url('./assets/fonts/Degular-Bold.woff') format('woff');
  font-weight: 700;
}

.primary-button {
  @include filled-button-template(var(--black), var(--darkSilver), var(--white));

  .MuiCircularProgress-root {
    color: var(--white) !important;
  }
}

.secondary-button {
  @include filled-button-template(var(--cerise), var(--oruby), var(--white));
}

.error-button {
  @include filled-button-template(var(--coralred), var(--metallicred), var(--white));

  .MuiCircularProgress-root {
    color: var(--white) !important;
  }
}

.success-button {
  @include filled-button-template(var(--apple), var(--japaneselaurel), var(--white));

  .MuiCircularProgress-root {
    color: var(--white) !important;
  }
}

.primary-text-button {
  @include text-button-template(var(--transparent), rgba(0, 0, 0, 0.08), var(--black));
}

.outline-primary-button {
  @include outline-button-template(var(--black), var(--brightGray), var(--black));

  .MuiCircularProgress-root {
    color: var(--black) !important;
  }
}
