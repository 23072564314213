.Sidebar {
  position: sticky;
  top: 40px;
  width: 184px;
  
  &__Link {
    text-decoration: none;
    align-items: center;
    color: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;  
  }
}

.SidebarChip {
  padding: 12px;
  display: flex;
  cursor: pointer;
  margin-bottom: 4px;
  justify-content: space-between;

  &__Text {
    margin: 0;
    padding: 0;
    opacity: 0.6;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;

    &--Completed {
      opacity: 1;
    }
  }

  &--Active {
    background-color: #f5f5f5;
  }
}

.Collapsable {
  padding-left: 24px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  margin-top: -4px;
  margin-bottom: 4px;

  :nth-child(2n) {
    margin: 12px 0;
  }

  &__Text {
    margin: 0;
    padding: 0;
    opacity: 0.5;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: black;

    &--Active {
      opacity: 1;
    }
  }
}
