:root {
  --errorRed: rgba(255, 0, 0, 1);
  --greenBlue: rgba(40, 110, 180, 1);
  --white: white;
  --antiFlashWhite: #f0f0f0;
  --black: #000000;
  --gray12: #BDBDBD;
}

.App {
  text-align: center;
}

* {
  font-family: 'Degular';
}


html,
body {
  height: 100%;
}
