@mixin text-resetter {
  margin: 0;
  padding: 0;
}

.Drawer {
  width: 402px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex: 1 0;
  flex-direction: column;

  &__HeaderText {
    @include text-resetter;
  }

  &__HeaderDescription {
    @include text-resetter;

    margin-top: 4px;
    margin-bottom: 20px;
    opacity: 0.4;
  }

  &__SelectInfo {
    & > :nth-child(2n) {
      margin-top: 20px;
    }
  }

  &__Role {
    margin-top: 40px;
  }

  &__Roles {
    & > :nth-child(2n) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__RoleHeaderText {
    @include text-resetter;
    
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.15px;
  }

  &__AddNewRoleText {
    font-size: 12px;
    letter-spacing: 0.15px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  &__AdvertiserDropdownShimmer {
    & :global .MuiSkeleton-root {
      width: '100%';
      height: 60px;
    }
  }

  &__RoleDeleteAdornment {
    cursor: pointer;
  }

  &__RoleDeleteAdornmentImg {
    width: 12px;
    height: 12px;
  }
}

.Button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  box-sizing: border-box;
  padding-inline: 22px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%);

  & :global .MuiCircularProgress-root {
    color: var(--white);
    margin-left: 10px;
  }

  &__Text {
    @include text-resetter;
    
    color: var(--white);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.46;
    font-size: 15px;

    &--Black {
      color: var(--Black);
    }
  }

  &--Transparent {
    background-color: var(--white);
    box-shadow: none;
  }

  &--Disabled {
    cursor: not-allowed;
    background-color: gray;
  }
}

.Footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 40px;
  position: sticky ;
  bottom: 0;
  background-color: var(--white);
}
