.UGCTalentsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  grid-gap: 24px;

  &--Landscape {
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  }
}

.CastingTalentsContainer {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  width: 100%;
  padding-bottom: 50px;
}
