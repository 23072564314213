@mixin text-resetter {
  margin: 0px;
  padding: 0px;
}

.Drawer {
  width: 440px;
  padding: 40px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &__Header {
    margin-bottom: 32px;
  }

  &__HeadingText {
    @include text-resetter;

    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.25px;
    margin-bottom: 6px;
  }

  &__DescriptionText {
    @include text-resetter;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
  }

  &__SpecificationRow {
    display: flex;

    :first-child {
      margin-right: 14px;
    }
  }

  &__SpecificationRowText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &__ProjectSpecifications {
    height: 100%;

    & > * + * {
      margin-top: 16px;
    }
  }

  &__AdvertiserText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &__ProjectNameText {
    @include text-resetter;

    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
  }

  &__ProjectInfo {
    margin-bottom: 24px;

    & > * + * {
      margin-top: 8px;
    }
  }

  &__Footer {
    position: sticky;
    display: flex;
    bottom: 0px;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 40px;
    background-color: var(--white);
  }

  &__FooterButtonText {
    @include text-resetter;

    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-align: left;
    text-transform: uppercase;
    color: var(--white);

    &--Black {
      color: var(--black);
    }

    &--Small {
      font-size: 12px;
    }
  }
}
