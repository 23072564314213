.UGCLayout {
  flex-grow: 1;
  display: flex;

  &__Main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__MainHeroSection {
    padding: 40px;
    padding-bottom: 0px;
    flex: 1;
    display: flex;
    position: relative;
  }

  &__MainHeroActiveTab {
    padding-left: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__Footer {
    height: 90px;
    display: flex;
    position: sticky;
    bottom: 0;
    background-color: var(--white);
    justify-content: space-between;
    padding-inline: 40px;
    align-items: center;
  }
  
  &__FooterButton {
    height: 42px;
    display: flex;
    align-items: center;
    padding-inline: 22px;
    justify-content: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.46;
    background: none;
    border: 1px solid #808080;
    cursor: pointer;

    &--TransparentBorder {
      border: none;
    }

    &--Filled {
      background-color: var(--black);
      color: var(--white);
    }
  }

  &__FooterButtonRow {
    display: flex;

    & :first-child {
      margin-right: 20px;
    }
  }
}
