.Main {
  &__SubSelectSpinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

.EmptyProfile {
  background-color: #e7f0f8;
  padding: 10px 0 10px 15px;

  &__Text {
    margin: 0;
    font-size: 13px;
  }
}
