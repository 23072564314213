.AdvertiserDetail {
  &__UploadLogo {
    display: flex;
    align-items: center;
    border: 1px solid var(--black);
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;

    & :global(.MuiCircularProgress-root) {
      margin-left: 10px;
    }
  }

  &__UploadLogoText {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.46;
    text-transform: uppercase;
  }
}
