.Drawer {
  display: flex;
  flex: 1;
  height: 0;
  flex-direction: column;

  &__BottomCTA {
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    gap: 10px;
	}

  &__AddNewSelectText {
    font-size: 12px;
  }
}
