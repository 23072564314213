.ReferenceLinksList {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 750px;

  &__ReferenceLinkItem {
    display: flex;
    flex-direction: row;
    padding: 12px 12px 12px 10px;
    align-items: center;
    gap: 10px;
    border: 1px solid rgb(0 0 0 / 8%);
    box-shadow: 0px 5px 20px 3px rgb(0 0 0 / 10%);
  }

  &__LinkIcon {
    font-size: 12px;
    display: flex;
    padding: 6px;
    align-items: center;
    border-radius: 100%;
    color: var(--white);
    background: var(--black);
  }

  &__Link {
    max-width: 150px;
    flex-shrink: 0;
    color: var(--sonic-silver);
    max-height: 52px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.15px;
    text-decoration: none;
    word-wrap: break-word;

    &:hover {
      text-decoration: underline;
    }
  }

  &__CloseIcon {
    font-size: 14px;
    cursor: pointer;
    color: rgb(0 0 0 / 54%);
  }
}
