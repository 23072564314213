.Videos {
  height: 100%;
  width: 100%;

  &__Masonary {
    height: 100%;
    width: 100%;
    padding-top: 20px;
  }

  &__Empty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__Image {
      height: 250px;
    }

    &__Text {
      font-size: 34px;
      font-weight: 600;
    }
  }
}
