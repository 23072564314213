.cropper-line {
    background-color: transparent;
}

.cropper-point {
    background-color: white;
}

.cropper-view-box {
    outline: 2px dotted white;
    outline-color: white;
}

.img-preview {
    overflow: hidden;
}
  