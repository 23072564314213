.Tooltip {  
  &__Container {
    background-color: var(--white);
    border-radius: 6px;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 22%);
    opacity: 0;
    padding: 20px;
    position: absolute;
    transition: all 0.5s;
    visibility: hidden;
    width: 321px;
    z-index: 100;
  }

  &__Popper {
    display: flex;
    place-items: center;
    cursor: pointer;
  }
}

.Tooltip:hover .Tooltip__Container {
  visibility: visible;
  opacity: 1;
}
