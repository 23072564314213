.TagCheckbox {
  &__Label {
    font-size: 14px;
    color: var(--black);
  }

  & :global .MuiFormLabel-root {
    font-size: 14px;
    color:  var(--black) !important;
  }
  
  & :global .MuiFormLabel-root.Mui-error {
    color:  var(--errorRed) !important;
  }
  
  &__Icon {
    color: var(--black);
  }

  & :global .MuiCheckbox-root {
    padding: 0;
    margin: 0;
    margin-right: 1em;
  }
  
  &__Chip {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 20px;
    margin-bottom: 10px;
    text-transform: capitalize;
    border: 1px solid #bdbdbd;
    color: var(--black);
    font-weight: 700;
    font-size: 16px;
    transition: all .3s ease;

    &--selected {
      border: 1px solid transparent;
      background-color: #616161;
      color: var(--white);
    }

    &--disabled {
      border: 1px solid transparent;
      background-color: #BDBDBD;
      color: #00000042;
    }
  }
}

