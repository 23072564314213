.Drawer {
  display: flex;
  flex-direction: column;
  flex: 1;

  p {
    margin: 0;
  }

  width: 548px;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 28px 40px;

  &__HeaderClose {
    cursor: pointer;
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Main {
    flex: 1;
  }

  &__MainControls {
    display: flex;
    justify-content: space-between;
  }

  &__MainControlArrow {
    cursor: pointer;

    &--Right {
      transform: rotate(180deg);
    }
  }

  &__MainPhotoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__Watermark {
    position: absolute;
  }

  &__MainPhoto {
    margin-top: 22px;
    width: 100%;
    max-height: 575px;
    object-fit: contain;
  }

  &__Feedback {
    margin: 24px 0 28px 0px;
  }

  &__FeedbackTitle {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__FeedbackTitleText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15px;
    padding-bottom: 10px;
  }

  &__FeedbackAgoText {
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15px;
    margin-top: 120px;
  }

  &__MainVideoTitleText {
    font-size: 20px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.15px;
  }

  &__TitleTag {
    padding: 4px 8px;
    background-color: var(--cultured);
    font-size: 14px;
    margin-left: 12px;
    font-weight: 400;

    &--Hidden {
      display: none;
    }
  
    &--Orange {
      background-color: var(--orange);
      color: var(--white);
    }
  
    &--Green {
      background-color: var(--green);
      color: var(--white);
    }
  }

  &__MainVideoInfo {
    text-align: center;
  }

  &__MainVideoDateText {
    text-align: center;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.15px;
  }

  &__Footer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__FooterAdminActions {
    flex: 1 0 100%;
    margin-bottom: 16px;
  }

  &__FooterRevision {
    border: 1px solid var(--cultured);
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      height: 16px;
    }
  }

  &__FooterRevisionText {
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
  }

  &__MainVideo {
    margin-top: 22px;
    aspect-ratio: 9 / 16;
    height: 500px;
    
    &--Landscape {
      width: 100%;
      aspect-ratio: 16/9;
      height: fit-content;
    }
  }

  &__FooterCTAContainer {
    display: flex;
    gap: 15px;
  }

  &__AssetDownload {
    display: inline-block;
    cursor: pointer;
    background: transparent;
    padding: 10px 80px;
    border: 1px solid var(--black-inactive);
    color: var(--black-inactive);
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.Hidden {
  display: none;
}
