
.Chip {
  padding: 7px 22px;
  border: 1px solid var(--black);
  width: fit-content;
  background-color: var(--cultured);

  &__Text {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.15px;
    text-align: center;
  }
}

.Drawer {
  width: 508px;
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__Main {
    flex-grow: 1;
  }

  &__Accordion {
    margin-top: 30px;

    & :global .MuiAccordion-root {
      color:  var(--black) !important;
      background: transparent;
      padding: 0;
    }
  }

  &__AccordionChipsTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.15px;
    text-align: left;
  }

  &__AccordionContent {
    margin-left: 30px;
    background-color: var(--lotion);
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
  }

  &__AccordionChips {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__Heading {
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  &__Description {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;

  }

  &__Footer {
    position: sticky;
    bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: var(--white);
    text-transform: uppercase;

    & > :first-child {
      margin-right: 10px;
    }
  }

  &__FooterText {
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;


    &--White{
      color: var(--white);
    }
  }
}
