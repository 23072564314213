.Main {
  margin-inline: 32px;
  flex: 1;
  position: sticky;
  top: 20px;
  height: 100dvh;
  overflow-y: auto;

  &__VideoContent {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    padding-top: 40px;

    &--Empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--Landscape {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  &__PhotoContent {
    padding-top: 40px;
  }

  &__PhotoContentNotSubmitted {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  &__Skeleton {
    & :global(.MuiSkeleton-root) {
      height: 450px;
      width: 100%;
    }

    &--Landscape {
      & :global(.MuiSkeleton-root) {
        height: 100%;
        width: 100%;
      }
    }
  }

}

.AssetsTab {
  display: flex;

  &__ItemContainer {
    flex: 1;
    display: flex;
  }

  &__Item {
    all: unset;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-right: 15px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &--Active {
      border-bottom: 2px solid var(--black);
    }
  }

  & :global(.MuiSkeleton-root) {
    height: 50px;
    width: 100px;
    margin-right: 15px;
  }
}
