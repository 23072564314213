@mixin text-resetter {
  margin: 0;
}

.SidebarChip {
  all: unset;
  border: 1px solid var(--chinese-white);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 14px 12px;
  transition: all 0.2s ease-in-out;

  &--Active {
    background-color: var(--black);
    box-shadow: 0px 4px 14px rgb(0 0 0 / 25%);
    border: 1px solid transparent;
  }

  &__Avatar {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 8px;
  }

  &__Initials {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 8px;
    background-color: #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
  }

  &__InitialsText {
    @include text-resetter;
  }

  &__Main {
    flex: 1;
  }

  &__Info {
    display: flex;
    align-items: center;

    & * + * {
      margin-inline: 6px;
    }
  }

  &__MainTalentText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;

    &--Active {
      color: var(--white);
    }
  }

  &__MainApprovedText {
    @include text-resetter;

    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    text-align: left;

    &--Active {
      color: var(--white);
    }
  }

  &__MainReviewText {
    @include text-resetter;

    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.15px;

    &--Active {
      color: var(--white);
    }
  }

  &__GreenIndicator {
    height: 6px;
    width: 6px;
    background-color: var(--apple);
    border-radius: 3px;
  }
}

.Sidebar {
 & > :nth-child(n) {
    margin-bottom: 16px;
  }

  position: sticky;
  top: 20px;
  overflow-y: auto;
  height: 100dvh;
  flex: 0.3;
  min-width: 292px;
  max-width: 400px;

  &__Skeleton {
    & :global(.MuiSkeleton-root) {
      height: 68px;
    }
  }
}
