@mixin text-resetter {
  margin: 0;
  padding: 0;
}

@mixin description {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

@mixin heading {
  font-size: 20px;
  line-height: 32px;
}

.Additional {
  margin-bottom: 32px;

  &__Title {
    @include text-resetter;

    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.25;
    margin-bottom: 12px;
  }

  &__IntroHooks {
    max-width: 720px;
    margin-top: 25px;
    margin-bottom: 33px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  &__GrayBox {
    max-width: 660px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--lotion);
  }

  &__StillImages {
    max-width: 720px;
    padding: 25px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--Empty {
      margin-bottom: 30px;
    }
  }
  
  &__StillImagesInput {
    margin-top: 5px;
  }

  &__ImageStyleText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 20px;
  }

  &__ImageStyleDescriptionText {
    @include text-resetter;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 12px;
  }
}

.RadioInput {
  & :global .MuiFormLabel-root {
    color: var(--black) !important;
  }

  & :global .MuiFormLabel-root.Mui-error {
    color: var(--errorRed) !important;
  }

  &__Icon {
    color: var(--black);
  }
}
