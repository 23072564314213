.ProjectDetail {
  display: flex;

  &__Header {
    padding: 40px 40px 0px;
  }

  &__HeaderShareButton {
    all: unset;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4px;
    border: 1px solid var(--black);
    text-transform: uppercase;
    padding: 5px 10px;
    margin-left: auto;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &>img {
      margin-right: 10px;
    }

    &--Hidden {
      display: none;
    }
  }
}
