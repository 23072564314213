.ImageRadio {
  padding: 11px 20px;
  width: fit-content;
  display: flex;
  cursor: pointer;
  border: 1px solid var(--gray12);
  box-sizing: border-box;
  background-color: var(--white);
  transition: all .5s ease;
  
  &__Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__ContainerText {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }

  &--Active {
    border: 1px solid transparent;
    background-color: var(--granite-gray);
    color: var(--white);
  }
}
