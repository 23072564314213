.RadioInput {
  &__Label {
    font-size: 16px;
    color: var(--black);
  }

  & :global .MuiFormLabel-root {
    color:  var(--black) !important;
    font-family: inherit;
    font-size: 16px;
  }
  
  & :global .MuiFormLabel-root.Mui-error {
    color:  var(--errorRed) !important;
  }

  & :global .MuiTypography-root {
    font-family: inherit;
  }
  
  &__Icon {
    color: var(--black);
  }
}
