.ProjectsWrapper {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  padding: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;

  @media (min-width: 1450px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  &__NoData {
    height: auto;
    width: auto;
    text-align: center;
    margin: auto;
  }
}
