.Direction {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__Section {
    width: 725px
  }
}

.Skeleton {
  max-width: 784px;

  &__Heading {
    & :global .MuiSkeleton-root {
      height: 40px;
      border-radius: 4px;
      margin-bottom: 20px;
    }
  }

  &__Description {
    & :global .MuiSkeleton-root {
      height: 30px;
      width: 300px;
      margin-bottom: 20px;
    }
  }
  
  &__Input {
    & :global .MuiSkeleton-root {
      height: 40px;
      width: 500px;
      border-radius: 4px;
      margin-bottom: 50px;
    }
  }
}

.Footer {
  height: 90px;
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  justify-content: space-between;
  align-items: center;
  margin-left: -200px;
  border-top: 1px solid var(--platinum);

  &__ButtonRow {
    display: flex;

    &> :first-child {
      margin-right: 20px;
    }
  }

  &__Button {
    height: 38px;
    padding: 1px 22px;
  }

}
