.ShortURL {
  margin: 16px 0;
}

.Copiable {
  background-color: #DEDEDE;
  border-radius: 4px;
  cursor: copy;
  padding: 8px;
}
