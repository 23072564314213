@mixin text-resetter {
  margin: 0;
  padding: 0;
}

.ScriptRow {
  display: flex;
  align-items: center;

  &__TitleContainer {
    min-width: 65px;
    max-width: 70px;
    padding: 10px 0;
    border-right: 1px solid var(--bright-gray);
  }

  &__TitleText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.15px;
    margin-top: 5px;
  }

  &__TitleDescription {
    padding-left: 20px;
  }
}


.Overview {
  display: flex;
  padding: 32px 0px 0px 40px;

  & ul {
    padding-left: 17px;
    margin: 0;

    & li {
      font-size: 16px;
    }
  }

  &__Sidebar {
    margin-right: 32px;
    position: sticky;
    top: 40px;
  }

  &__SidebarShimmer {
    & :global .MuiSkeleton-root {
      margin-bottom: 12px;
      width: 184px;
      height: 35px;
    }
  }

  &__Hero {
    width: 60%;
    max-width: 784px;
  }

  &__HeroTitleShimmer {
    & :global .MuiSkeleton-root {
      margin-bottom: 30px;
      width: 724px;
      height: 35px;
    }
  }

  &__HeroHeadingShimmer {
    & :global .MuiSkeleton-root {
      margin-bottom: 12px;
      width: 504px;
      height: 35px;
    }
  }


  &__HeroDescriptionShimmer {
    & :global .MuiSkeleton-root {
      margin-bottom: 100px;
      width: 300px;
      height: 35px;
    }
  }

  &__SidebarChip {
    width: 184px;
    box-sizing: border-box;
    padding: 12px;
    cursor: pointer;

    &--Active {
      background-color: var(--cultured);
    }
  }

  &__SectionHeaderText {
    margin-bottom: 6px;
  }

  &__OldSectionHeaderDescriptionText {
    margin-bottom: 30px;
    font-size: 16px;
    white-space: pre-line;
  }

  &__SectionHeaderDescriptionText {
    margin-bottom: 0px;
    font-size: 16px;
    white-space: pre-line;
    word-break: break-all;
  }

  &__TalentProfileTitleText {
    @include text-resetter;

    font-size: 20px;
    margin-bottom: 8px;
  }

  &__Title {
    @include text-resetter;

    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-bottom: 24px;
  }

  &__BrandSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__BrandSectionBrandName {
    margin-left: 10px;
  }

  &__BrandInitialsContainer {
    border-radius: 4px;
    border: 0.5px solid var(--chinese-white);
    margin-right: 10px;
    width: 42px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cultured);
    font-size: 18px;
    font-weight: bold;
  }

  &__GrayBox {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--lotion);
  }

  &__ProjectDetail {
    padding-bottom: 50px;
    border-bottom: 1px solid var(--chinese-white);
  }

  &__ReferenceLinkList {
    margin-top: 20px;
    max-width: 750px;
  }

  &__Directions {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--chinese-white);
  }

  &__Deliverables {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--chinese-white);
  }

  &__DeliverableScripts {
    &> :nth-child(2n) {
      margin: 45px 0px;
    }

    margin-bottom: 36px;
  }

  &__DeliverableVideoCount {
    @include text-resetter;

    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    text-align: left;
    margin-bottom: 30px;
  }

  &__DeliverableVideoSpecs {
    display: flex;
    margin-bottom: 30px;

    &> :first-child {
      margin-right: 148px;
    }
  }

  &__Talent {
    padding-top: 50px;
    margin-bottom: 60px;
  }

  &__Product {
    margin-bottom: 60px;
  }

}

.Footer {
  padding: 24px 0;
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  justify-content: space-between;
  margin-inline: 40px;
  align-items: center;
  border-top: 1px solid var(--platinum);

  &--Hidden {
    visibility: hidden;
  }

  &__Row {
    display: flex;
    gap: 20px;
  }

  &__MoreButton {
    all: unset;
    display: flex;
    align-items: center;
    border: 1px solid var(--black);
    padding: 10px 20px;
    cursor: pointer;

    :first-child {
      margin-right: 15px;
    }

    &--Hidden {
      visibility: hidden;
    }
  }

  &__MoreButtonText {
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__Button {
    height: 42px;
    display: flex;
    text-decoration: none;
    align-items: center;
    color: var(--black);
    padding-inline: 22px;
    justify-content: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.46;
    background: none;
    border: 1px solid var(--gray);
    cursor: pointer;

    &--TransparentBorder {
      border: none;
    }

    &--Filled {
      background-color: var(--black);
      color: var(--white);

      & :global .MuiCircularProgress-root {
        color: var(--white);
      }
    }
  }

  &__ButtonRow {
    display: flex;

    &> :first-child {
      margin-right: 20px;
    }
  }

}
