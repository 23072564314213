.InputBox {
  & :global .MuiInputBase-input, & :global .MuiFormLabel-root, & :global .MuiInput-input {
    font-family: Degular, sans-serif;
  }

  & ::placeholder {
    color: rgb(0 0 0 / 60%) !important;
    opacity: 1 !important;
  }

  &--large {
    font-size: 60px;
    font-weight: 700;
    
    & :global .MuiInputBase-input, & :global .MuiFormLabel-root {
      line-height: 1;
      font-size: 60px;
      font-weight: 700;
    }
  }

  &--medium {
    font-size: 32px;
    font-weight: 600;
    
    & :global .MuiInputBase-input, & :global .MuiFormLabel-root {
      font-size: 32px;
      font-weight: 600;
    }
    
    & :global .MuiInput-root {
      margin-top: 30px;
    }
    
    & :global .MuiFormHelperText-root {
      font-size: 28px;
    }
  }
  
  &--normal {
    font-size: 16px;
    
    & :global .MuiInputBase-input, & :global .MuiFormLabel-root {
      font-size: 16px;
    }
  }

  &--small {
    font-size: 14px;
    
    & :global .MuiInputBase-input, & :global .MuiFormLabel-root {
      font-size: 14px;
    }

    & :global .MuiInput-root {
      margin-top: 12px;
    }
    
    & :global .MuiFormHelperText-root {
      font-size: 12px;
    }
  }
}
