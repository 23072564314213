.Main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;


  &__DownloadAssetsProgressContainer {
    background: var(--white);
    box-shadow: 10px 15px 54px 0px rgb(0 0 0 / 13%);
    height: 70px;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: contain;
    padding-inline: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__DownloadAssetsProgressTextConatiner {
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__DownloadAssetProgressText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.15px;
    margin-left: 10px;

    &--Error {
      color: var(--coralred);
    }
  }

  &__DownloadAssetsErrorImg {
    cursor: pointer;
  }
}
