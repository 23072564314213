.Card {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  transition: all 0.3s ease-out;
  cursor: pointer;
  aspect-ratio: 9/16;
  
  &--Landscape {
    aspect-ratio: 16/9;
  }
  
  &__Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(90 90 90 / 0%), rgb(90 90 90 / 20%), rgb(0 0 0 / 90%) 100%);

    &--Center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  &__Tag {
    position: absolute;
    right: 0;
    top: 20px;
    padding-inline: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-transform: capitalize;

    &--Green {
      background-color: var(--green);
    }

    &--Orange {
      background-color: var(--orange);
    }


    &--Blue {
      background-color: var(--blue);
    }

    &--Pink {
      background-color: var(--pink);
    }

    &--Gold {
      background-color: var(--gold);
    }

    &--Timer {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  &__TagText {
    font-size: 13px;
    margin: 0;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.15px;

    &--Bold {
      font-weight: bold;
    }
  }

  &__Play {
    position: absolute;
    left: calc(50% - 21px);
    top: calc(50% - 21px);
    z-index: 9;
    height: 42px;
    width: 42px;
    background-color: rgba(132 132 132 / 30%);
    border-radius: 100px;
    backdrop-filter: blur(22px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__PlayTriangle {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 8px solid var(--white);
    border-bottom: 5px solid transparent;
  }

  &__Photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__Link {
    box-sizing: border-box;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    text-decoration: none;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__Avatar {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
    overflow: hidden;
    object-fit: cover;
    border-radius: 100px;
  }

  &__AvatarInitial {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: #EEE;
    display: grid;
    place-items: center;
    color: var(--black);
  }

  &__AvatarInitialText {
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
  }

  &__FooterTitleText {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__FooterSubtitleText {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  &__Footer {
    display: flex;
    padding: 13px;
    color: var(--white);
  }

  &__FooterTalentInfo {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    overflow: hidden;
    justify-content: center;
  }

  &__CloseButton {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    position: absolute;
    right: 16px;
    background-color: rgb(0 0 0 / 30%);
    top: 10px;
    display: grid;
    place-items: center;
    z-index: 9;
    cursor: pointer;
  }

  &__CrossIcon {
    height: 14px;
  }

  &__BtnWrapper {
    height: 48px;
    display: flex;
    justify-content: space-between;
  }

  &__DetailButton {
    background: rgba(255 255 255 / 24%);
    backdrop-filter: blur(42px);
    width: 75%;
    font-size: 15px;
    font-weight: bold;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
  }

  &__LikeButton {
    font-weight: bold;
    text-align: center;
    border: none;
    width: 20%;
    cursor: pointer;
    background: #EEE;

    &--Liked {
      background-color: #E1306C;
    }

    &--Disabled {
      background-color: #E0E0E0;
      cursor: not-allowed;
    }

    img {
      width: 20px;
    }
  }
}

.Cursor__Disabled {
  cursor: not-allowed !important;
}
