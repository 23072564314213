.Drawer {
  &__Container {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px;
  }

  &__HeaderText {
    font-size: 34px;
    font-weight: bold;
    margin: 0;
  }

  &__HeaderDescriptionText {
    font-size: 14px;
    letter-spacing: 0.15px;
    opacity: 0.4;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__PasswordErrorText {
    font-size: 12px;
    color: var(--coralred);
  }

  &__CopyLinkCTAWrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  &__GenerateLinkCTA {
    margin-top: 20px;
  }

  &__SwitchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__CopyLinkAlertText {
    font-size: 14px;
    letter-spacing: 0.15;
    opacity: 0.4;
    padding-left: 15px;
  }

  &__ShareLinkContainer {
    margin-top: 20px;
    box-sizing: border-box;
    min-height: 12px;
    width: 100%;
    word-break: break-all;
    padding: 10px;
    max-height: 150px;
    overflow-y: auto;
    background: rgb(240 240 240 / 100%);
  }

  &__ShareLinkText {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    margin: 0;
  }

  &__Footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
