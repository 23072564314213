.DatePicker {
  &__Label {
    font-size: 14px;
    color: black;
    font-family: Degular, sans-serif;
  }
  
  & :global .MuiFormLabel-root {
    color:  black !important;
    font-family: inherit;
  }

  & :global .MuiInputBase-root {
    font-family: inherit;
  }
  
  & :global .MuiFormLabel-root.Mui-error {
    color:  #f00 !important;
  }
}
