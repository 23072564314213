.Talent {
  flex: 1;
  margin-bottom: 100px;
  
  &__Form {
    max-width: 935px;

    > div {
      margin-bottom: 25px;
    }
  }

  &__Creator {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px)  {
      flex-direction: column;
    }

    .Talent__CreatorDescription {
      background-color: var(--cultured);
      margin-left: 30px;
      border-radius: 6px;
      padding: 12px;
      height: fit-content;

      @media only screen and (max-width: 768px)  {
        margin-left: 0;
        margin-top: 20px;
      }
      
      span, li {
        font-size: 14px;
      }

      ul {
        margin: 5px 0;
        padding-left: 25px;
      }
    }
  }

  &__Shimmer {
    & :global .MuiSkeleton-root {
      margin-bottom: 0px;
      width: 200px;
      height: 100px;
    }
  }

  &__Compensation {
    width: 230px;
  }
  
  &__AccordionForm > div {
    margin-bottom: 18px;
  }
  
  &__CTAText {
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.46px;
  }

  &__ProfileContainer {
    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  &__ProfileShimmer {
    & :global .MuiSkeleton-root {
      width: 100%;
      height: 65px;   
    }

    & > :nth-child(2) {
      width: 75%;
    }
  }
}

.Footer {
  height: 90px;
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  justify-content: space-between;
  align-items: center;
  margin-left: -200px;
  border-top: 1px solid var(--platinum);

  &__ButtonRow {
    display: flex;

    &> :first-child {
      margin-right: 20px;
    }
  }

  &__Button {
    height: 38px;
    padding: 1px 22px;
  }

}
