.Drawer {
  &__Wrapper {
    padding: 40px;
    height: 100%;
    max-width: 430px;
    display: flex;
    flex-direction: column;
  }

  &__BodyTimerBox {
    height: 48px;
    padding-inline: 19px;
    background-color: #4CAF50;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &--Expired {
      background-color: #F44336;
    }
  }

  &__BodyTimerBoxShimmer {
    margin-bottom: 32px;
  }

  &__BodyTimerBoxClose {
    cursor: pointer;
  }

  &__BodyTimerText {
    margin-left: 10px;
    font-size: 14px;
    flex: 1;
    color: var(--white);
  }
  
  &__Heading {
    margin: 0;
    font-size: 34px;
    font-weight: 700;
  }

  &__Description {
    font-size: 14px;
    color: #424242;
    margin-bottom: 0;
    margin-top: 10px;
    width: 70%;
  }

  &__Controlles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  &__ActionArrow {
    border-radius: 30px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.5s ease;

    &:hover {
      background-color: var(--antiFlashWhite);
    }
  
    &--right {
      transform: rotate(180deg);
    }
  }
  
  &__VideoContainer {
    aspect-ratio: 9 / 16;

    &--landscape {
      max-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }

  &__TabItem {
    cursor: pointer;
    padding: 10px 8px;
    transition: all 0.25s ease-in;
    border-bottom: 2px solid transparent;
    
    span {
      color: #424242;
    }
    
    &--active {
      border-bottom: 2px solid var(--black);
      
      span {
        color: black;
      }
    }
  }
  
  &__Footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: auto;
    padding-top: 20px;

    div {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__UserInfoData {
    display: inline-flex;
  }

  &__UserIconBox {
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEE;
    border-radius: 5px;

    span {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__UserIcon {
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
  }

  &__TabContainer {
    margin-top: 20px;
    max-width: 60%;
  
    &--landscape {
      max-width: 100%;
    }
  }

  &__UserInfoWrapper {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
  }

  &__UserInfoHeader {
    margin-left: 12px;
    flex-direction: column;
    justify-content: space-around;

    h3 {
      margin: 0;
      font-size: 20px;
    }

    span {
      font-size: 14px;
      color: rgba(0 0 0 / 60%);
    }
  }

  &__TabWrapper {
    display: flex;

    & :global .MuiSkeleton-root {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  
  &__Body {
    overflow: auto;
    padding-bottom: 14px;
  }
}

.Text__White {
  color: var(--white);
}

.Button__Tag {
  border: 1px solid black;
  display: flex;
  font-size: 13px;
  align-items: center;
  padding-inline: 12px;
  height: 30px;
  cursor: pointer;
  color: black;
}

.Button__Text {
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  font-size: 15px;

  &--Selected {
    margin-left: 15px;
  }
}

.Link__Button {
  text-decoration: none;
}
