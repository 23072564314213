.Skeleton {
  max-width: 784px;

  &__Heading {
    & :global .MuiSkeleton-root {
      height: 40px;
      border-radius: 4px;
      margin-bottom: 20px;
    }
  }

  &__Description {
    & :global .MuiSkeleton-root {
      height: 30px;
      width: 300px;
      margin-bottom: 20px;
    }
  }
  
  &__Input {
    & :global .MuiSkeleton-root {
      height: 40px;
      width: 500px;
      border-radius: 4px;
      margin-bottom: 50px;
    }
  }
}

.ProjectDetail {
  max-width: 725px;
  flex: 1;

  &__Spacer {
    height: 32px;
  }

  &__DropdownShimmer {
    & :global .MuiSkeleton-root {
      width: '100%';
      height: 60px;
    }
  }

  &__SubSection {
    max-width: 420px;
    
    & .ProjectDetail__Title {
      margin-top: 1em;
    }
  }
}


.Footer {
  height: 90px;
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  justify-content: flex-end;
  align-items: center;
  margin-left: -200px;
  border-top: 1px solid #E6E6E6;

  &__Button {
    height: 42px;
    display: flex;
    align-items: center;
    padding-inline: 22px;
    justify-content: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.46;
    background: none;
    border: 1px solid #808080;
    cursor: pointer;

    & :global .MuiCircularProgress-root {
      margin-left: 20px;
    }
  
    &--TransparentBorder {
      border: none;
    }
  
    &--Filled {
      background-color: var(--black);
      color: var(--white);

      & :global .MuiCircularProgress-root {
        color: var(--white);
      }
    }

 
    &--Disabled {
      cursor: not-allowed;
      background-color: rgba(0 0 0 / 12%);
      color: rgba(0 0 0 / 26%);
      border-color: transparent;
    }
  }
  
  &__ButtonRow {
    display: flex;
  
    & > :first-child {
      margin-right: 20px;
    }
  }

}

