@mixin text-resetter {
  margin: 0;
  padding: 0;
}

@mixin description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin heading {
  font-size: 20px;
  line-height: 32px;
}

@mixin row-bottom-spacer {
  margin-bottom: 32px;
}

.Videos {
  // #####################################
  //               video
  // #####################################
  &__Video {
    @include row-bottom-spacer;
  }

  &__VideoTitle {
    @include text-resetter;

    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.25;
    margin-bottom: 12px;
  }

  &__VideoCounter {
    margin-top: 12px;
  }

  // #####################################
  //               specification
  // #####################################

  &__Specification {
    display: flex;

    @include row-bottom-spacer;

    &> :first-child {
      margin-right: 100px;
    }
  }

  &__SpecificationOrientationRow {
    display: flex;

    &> :nth-child(2n) {
      margin-inline: 16px;
    }
  }

  &__Direction {
    @include row-bottom-spacer;
  }

  &__DirectionInputContainer {
    max-width: 725px;
  }

  &__Audition {
    margin-bottom: 33px;
  }

  &__GrayBox {
    max-width: 660px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--lotion);
  }

  &__ScriptVideo {
    max-width: 720px;
    margin-bottom: 33px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__ReferenceLink {
    @include row-bottom-spacer;
  }

  &__ReferenceLinkInputContainer {
    max-width: 735px;
    margin-bottom: 16px;
  }

  &__ReferenceLinkCloseIcon {
    cursor: pointer;
    color: rgb(0 0 0 / 54%);
  }

}
