.Header {
  background: var(--winterWizard);
  padding: 20px 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Title {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--black);
    display: inline-block;
    padding-right: 15px;
  }
  
  & :global .MuiInputBase-root {
    font-size: 32px;
    font-weight: 700;
    padding-right: 10px;
  }
}
