.CounterButton {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  border: 1px solid var(--gray12);

  &__Text {
    font-size: 20px;
  }

  &--Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.Counter {
  &__Wrapper {
    display: flex;
    align-items: center;
  }
  
  &__Main {
    width: 140px;
  }

  &__MainText {
    font-size: 60px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 700;
    border: 1px solid var(--gray12);
    border-width: 0;
    border-bottom-width: 1px;
    margin-inline: 20px;
  }
}
