.ProjectCard {
  min-width: 230px;
  max-height: 455px;
  height: 100%;
  width: 100%;
  aspect-ratio: 2 / 3.45;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;

  &__Wrapper {
    padding: 20px;
  }

  &__LogoWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    background: var(--white);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      align-self: center;
    }

    picture {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    span {
      font-size: 26px;
      font-weight: bold;
      line-height: normal;
    }
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Title {
    font-size: 24px;
    margin-top: 8px;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__OwnerName {
    font-size: 14px;
    margin-top: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  &__Status {
    max-width: 50%;
    font-size: 12px;
    overflow: hidden;
    padding: 5px 8px;
    white-space: nowrap;
    height: fit-content;
    text-overflow: ellipsis;
    border: 1px solid var(--black);
    text-transform: capitalize;
    margin-left: 10px;

    &--UGC {
      color: var(--white);
      background-color: var(--black);
    }
  }

  &__DetailRow {
    display: flex;
    margin-top: 16px;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }

  &__Footer {
    padding: 12px;
    display: flex;
    color: var(--black);
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    transition: all 0.3s ease;
    border-top: 1px solid rgb(0 0 0 / 8%);

    span {
      font-size: 15px;
      font-weight: bold;
      margin-right: 10px;
    }

    img {
      width: 24px;
      height: 24px;
      transition: all 0.3s ease;
    }

    &:hover {
      img {
        transform: translateX(5px);
      }

      background: rgb(0 0 0 / 5%);
    }
  }
  
}
