@mixin text-resetter {
  margin: 0;
  padding: 0;
}

.UGCProject {
  padding: 2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;

  &__GreetingsTextWrapper {
    width: 100%;
    max-width: 400px;
    padding: 0 30px;
  }

  &__WelcomeText {
    @include text-resetter;
    
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.25px;
    margin-top: 37px;
  }
  
  &__DescriptionText {
    @include text-resetter;
    
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 10px;
    margin-bottom: 44px;
  }
  
  &__ExitButton {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &__ExitIcon {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.45s;

    &:hover {
      background-color: var(--antiFlashWhite);
    }

    img {
      height: 18px;
    }
  }

  &__GreetingsWrapper {
    margin-bottom: 40px;

    img {
      height: 275px;
      width: auto;
    }
  }

  &__Button {
    border: none;
    background: none;
    padding-inline: 22px;
    height: 42px;
    flex-shrink: 0;
    font-family: inherit;
    background-color: var(--black);
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgba(0 0 0 / 14%), 0px 1px 5px rgba(0 0 0 / 12%);
  }

  &__ButtonText {
    color: var(--white);
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.46px;
    line-height: 26px;
  }
}
