.Card {
  &--Landscape {
    aspect-ratio: 16/9;
    max-height: 500px;
  }

  &__Overlay {
    &--Landscape {
      aspect-ratio: 16/9;
    }
  }

  &--NoMedia {
    cursor: not-allowed;
  }

  &__Photo {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
