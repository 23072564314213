.InterestedTalent {
  padding: 40px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));

  &--Landscape {
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  }

  &__Shimmer {
    :global .MuiSkeleton-root {
      height: 426px;      
    }
    
    &--Landscape {
      :global .MuiSkeleton-root {
        height: 222px;
      }
    }
  }
}

.NotFoundText {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.Toast {
  &__ViewSelectText {
    text-decoration: underline;
    cursor: pointer;
  }
}
