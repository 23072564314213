.Directions {
  margin-bottom: 50px;
  
  &__Title {
    margin: 0;
    padding: 0;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.25;
    margin-bottom: 12px;
  }

  &__Inputs {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__Direction {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__RadioContainer {
    display: flex;
    gap: 12px;
  }

  &__ThreeRadioGroup {
    display: flex;
    flex-direction: column;
    width: 172px;
  }
}
