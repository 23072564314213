@mixin text-resetter {
  margin: 0;
  padding: 0;
}

.ScriptRow {
  display: flex;
  align-items: center;

  &__TitleContainer {
    min-width: 65px;
    max-width: 70px;
    padding: 10px 0;
    border-right: 1px solid var(--bright-gray);
  }

  &__TitleText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.15px;
    margin-top: 5px;
  }

  &__TitleDescription {
    padding-left: 20px;
  }
}


.Deliverables {
  gap: 24px;
  display: flex;
  flex-direction: column;

  & ul {
    padding-left: 17px;
    margin: 0;

    & li {
      font-size: 16px;
    }
  }

  &__SectionHeaderText {
    margin-bottom: 6px;
  }

  &__OldSectionHeaderDescriptionText {
    margin-bottom: 30px;
    font-size: 16px;
    white-space: pre-line;
  }

  &__SectionHeaderDescriptionText {
    margin-bottom: 0px;
    font-size: 16px;
    white-space: pre-line;
  }

  &__Title {
    @include text-resetter;

    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-top: 24px;
  }

  &__GrayBox {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--lotion);
  }

  &__Directions {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--chinese-white);
  }

  &__DeliverableScripts {
    &> :nth-child(2n) {
      margin: 45px 0px;
    }

    margin-bottom: 36px;
  }

  &__DeliverableVideoCount {
    @include text-resetter;

    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    text-align: left;
  }

  &__DeliverableVideoSpecs {
    display: flex;
    margin-bottom: 30px;

    &> :first-child {
      margin-right: 148px;
    }
  }

  &__StillImageCardsContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
    margin-top: 24px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--chinese-white);

    &--Empty {
      padding-bottom: 0px;
    }
  }

  &__BRoll {
    margin-top: 50px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    background-color: var(--lotion);
  }

  &__StillImageTitleContainer {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    background-color: var(--lotion);
  }

  &__MainDeliverablesContainer {
   margin-top: 24px;
  }
}

.StillImageDeliverableCard {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--lotion);
  gap: 24px;

  &__OverviewContainer {
    gap: 24px;
    display: flex;
    flex-direction: column
  }

  &__TitleText {
    @include text-resetter;

    font-size: 34px;
    font-style: normal;
    letter-spacing: 0.25px;
  }

  &__CategoryText {
    @include text-resetter;

    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.15px;
  }

  &__DirectionText {
    @include text-resetter;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.15px;
    margin-bottom: 10px;
  }

  &__DescriptionText {
    @include text-resetter;

    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.15px;
    white-space: pre-line;
    word-wrap: break-word;
  }
}
