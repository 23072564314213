.Product {
  flex: 1;
  
  & > :nth-child(n) {
    margin-bottom: 32px;
    max-width: 784px;
  }
}

.Footer {
  height: 90px;
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  justify-content: space-between;
  align-items: center;
  margin-left: -200px;
  border-top: 1px solid #E6E6E6;

  &__Button {
    height: 42px;
    display: flex;
    align-items: center;
    padding-inline: 22px;
    justify-content: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.46;
    background: none;
    border: 1px solid #808080;
    cursor: pointer;

    & :global .MuiCircularProgress-root {
      margin-left: 20px;
    }
  
    &--TransparentBorder {
      border: none;
    }
  
    &--Filled {
      background-color: var(--black);
      color: var(--white);

      & :global .MuiCircularProgress-root {
        color: var(--white);
      }
    }
  }
  
  &__ButtonRow {
    display: flex;
  }
}
