@mixin text-resetter {
  margin: 0;
  padding: 0;
}

@mixin description {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

@mixin heading {
  font-size: 20px;
  line-height: 32px;
}


.UGCCheckboxInputRow {
  display: flex;

  &--RowTitleHidden {
    margin: 0;
  }
  
  &__TitleContainer {
    max-width: 80px;
    box-sizing: border-box;
    padding-right: 10px;
  }

  &__TitleContainerText {
    @include text-resetter;

    font-size: 16px;
    font-weight: 400;
  }
  
  &__DescriptionContainer {
    padding-left: 20px;
    width: 100%;
    max-width: 722px;


    &--CountHidden {
      padding-left: 0;
    }
  }

  &__DescriptionRow {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 23px;
    }
  }


  &__DescriptionCheckboxContainer {
    display: flex;
  }

  &__DescriptionText {
    @include text-resetter;
    @include description;
  }

  &__DescriptionTitle {
    @include text-resetter;
    @include description;
  }
  
  &__Separator {
    flex-basis: 2px;
    max-height: 60px;;
    background-color: var(--bright-gray);
  }

  &__Alert {
    padding: 0px 16px !important;
  }
}

.RadioInput {
  &__Label {
    font-size: 14px;
    color: var(--black);
  }

  & :global .MuiFormLabel-root {
    color:  var(--black) !important;
  }
  
  & :global .MuiFormLabel-root.Mui-error {
    color:  var(--errorRed) !important;
  }
  
  &__Icon {
    color: var(--black);
  }
}
