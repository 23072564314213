.Portfolio {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  &__Tab {
    display: flex;
    margin-left: 0;
    padding: 15px 40px;
    overflow-y: hidden;
    flex-direction: row;
    justify-content: flex-end;

    &--Public {
    	margin-left: 95px;
    }

    &__AddNewButton {
      display: flex;
      cursor: pointer;
      align-items: center;
      background: none;
      border: none;

      &__Icon {
        height: 14px;
        margin-right: 10px;
      }

      &__Text{
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        text-decoration: underline;
      }
    }
  }

  &__Preview {
    height: 100%;

    &__VideoContainer {
      height: 100%;
      padding: 0 40px;

      &--Public {
        padding-left: 135px;
      }
    }

    &__ImagesContainer {
      padding: 24px 35px 50px;

      &__NoImages {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__Image {
          height: 250px;
        }

        &__Text {
          font-size: 34px;
          font-weight: 600;
        }
      }

      &__ShimmerContainer {
        width: 100%;
        height: 100%;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        
  

        @media (max-width: 1024px) {
          justify-content: center;
        }
      }

      &__ShimmerContainer--Public {
      	padding-left: 70px;
      }
    }

    &__ImagesContainer--Empty {
      padding: 0;
    }

    &__SpinnerContainer {
      display: grid;
      padding: 50px 0;
      place-items: center;

      .MuiCircularProgress-root {
        margin-bottom: 10px;
        width: 24px !important;
        height: 24px !important;
      }

      &__Text {
        font-size: 14px;
      }
    }
  }

  &__ProfileVideoContainer {
    max-width: 280px;
    padding-top: 20px;
  } 
  
  &__ProfileEmpty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__ProfileEmptyImg {
    height: 250px;
  }

  &__ProfileEmptyText {
    font-size: 34px;
    font-weight: 600;
  }
}
