.Select {
  & :global .MuiFormLabel-root {
    font-family: inherit;
  }

  &__Option {
    display: flex;
    align-items: center;
  }
  
  &__OptionIcon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    object-fit: contain;
    border-radius: 3px;
  }

  &__InitialText{
    margin: 0;
    padding: 0;
  }

  &__InitialContainer {
    height: 25px;
    width: 25px;
    display: grid;
    place-items: center;
    background-color: #d3d3d3;
    border-radius: 3px;
    margin-right: 5px;
  }

  &__OptionValue {
    color: var(--black);
    font-family: Degular, sans-serif;
  }
}
