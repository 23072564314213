.TalentCard {
  box-sizing: border-box;
  position: relative;
  width: 400px;
  height: 600px;
}

.TalentCard--100 {
  width: calc(400px * 1);
  height: calc(600px * 1);
}

.TalentCard--75 {
  width: calc(400px * 0.75);
  height: calc(600px * 0.75);
}

.TalentCard--50 {
  width: calc(400px * 0.5);
  height: calc(600px * 0.5);
}

.TalentCard__Photo {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.TalentCard__Link {
	background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.64) 92%);
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.TalentCard__Name {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-size: 42px;
  line-height: 1;
  color: White;
  font-weight: 700;
  text-transform: capitalize;
  font-family: 'Degular';
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end;
}

.TalentCard__Name--100 {
  font-size: calc(42px * 1);
}

.TalentCard__Name--75 {
  font-size: calc(42px * 0.75);
}

.TalentCard__Name--50 {
  font-size: calc(42px * 0.5);
}

.TalentCard__Name span {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}