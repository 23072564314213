.Thumbnail {
  position: relative;
  cursor: pointer;
  width: 100%;
  margin: 0;

  &__VideoJSContainer {
    display: block;
  }

  &__VideoJSContainer--Hidden {
    display: none;
  }

  &__Play {
    position: absolute;
    z-index: 8;
    bottom: 12px;
    left: 12px;
    background-color: none;

    &__Image {
      height: 32px;
    }
  }

  &__PosterImage {
    height: 100%;
    width: 100%;
  }
}

.Processing {
  width: 100%;
  height: 224px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f0f0f0;

  :first-child {
    margin-bottom: 15px;
  }
  
  &__Text {
    text-align: center;
    margin: 0;
  }
}
