.Project {
  &__FilterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
  }

  &__FilterDropdown {
    margin-bottom: 0.5em;
  }

  &__FilterButtonText {
    font-weight: bold;
    font-size: 14px;
    margin-right: auto;
    text-transform: uppercase;
  }

  &__FilterDropdownMenu {
    & :global .MuiMenu-list {
      width: 120px !important;
      text-transform: uppercase;
    }

    & :global .Mui-selected {
      background-color: #eee !important;

      &:hover {
        background-color: #eee !important;
      }
    }
  }

  &__TabWrapper {
    display: flex;
  }

  &__TabItem {
    cursor: pointer;
    padding: 10px 8px;
    transition: all 0.15s ease-in;

    span {
      font-size: 16px;
      font-weight: 500;
      color: #9e9e9e;
      text-transform: capitalize;
    }

    &--active {
      color: var(--black);
      border-bottom: 2px solid #000;

      span {
        color: var(--black);
      }
    }

    &:hover {
      span {
        color: var(--black);
      }
    }
  }

  &__CreateProjectCTAWrapper {
    gap: 20px;
    display: flex;
  }
}
