.PortfolioTab {
  &__Wrapper {
    display: flex;
    width: 100%;
  }

  &__Box {
    border-bottom: 2px solid white;
    margin-right: 16px;
    cursor: pointer;
    transition: all .5s ease;
    padding-bottom: 10px;
    height: auto;
    
    span {
      opacity: 0.5;
      font-size: 18px;
      padding: 0 5px;
    }
    
    &--active {
      border-bottom: 2px solid var(--black);

      span {
        opacity: 1;
      }
    }
  }
}
