.Accordion {
  & :global .MuiAccordion-root {
    background-color: var(--lotion);
    color:  var(--black) !important;
    border-radius: 0px !important;
    box-shadow: none;
    padding: 20px;
    align-self: center;
  }
  
  & :global .MuiAccordionSummary-root, :global .MuiAccordionDetails-root, :global .MuiAccordionSummary-content {
    padding: 0;
  }

  & :global .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
      min-height: 0;
    }
  }

  & :global .MuiAccordionSummary-root {
    min-height: 0;

    &.Mui-expanded {
      min-height: 0;
    }
  }

  & :global .MuiAccordionDetails-root {
    margin-top: 20px;
  }

  & :global .MuiCheckbox-root {
    padding: 0;
    margin-right: 10px;
  }
  
  &__Header {
    display: flex;
    flex: 1;
    align-items: center;
    color: var(--black);
    align-self: center;
  }
  
  &__HeaderText {
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
  }

  &__HeaderCTA {
    margin-left: auto;
    margin-right: 10px;

    img {
      margin-right: 5px;
    }

    & :global .MuiButton-root {
      color: var(--black);
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      
      &:hover {
        background-color: var(--bright-gray);
      }
    }
  }

  &__Summary {
    background-color: transparent !important;
  }

  &__Details {
    padding-top: 20px;
  }

  &__Input {
    font-size: 20px;
    font-weight: 700;
    border-width: 0 0 1.5px;
    border-color: var(--black);
    outline: none;
    background-color: transparent;

    &--error {
      border-color: var(--errorRed);
    }
  }

  &__ErrorText {
    font-size: 0.75em;
    margin: 5px 0;
    color: var(--errorRed);
  }
}
